<template>
  <v-autocomplete
    v-model="choice"
    v-bind="$attrs"
    :items="choices"
    :label="label"
    :item-value="itemValue"
    :item-text="itemText"
    dense
    :multiple="multiple"
    small-chips
    clearable
    menu-props="offsetY"
    @change="$emit('update:value', choice)"
  >
    <template #prepend-inner>
      <v-icon
        small
        class="mt-1"
        color="grey"
      >
        {{ icon }}
      </v-icon>
    </template>
  </v-autocomplete>
</template>
<script>
  export default {
    props: {
      value: {
        type: Array,
        default: () => [],
      },
      choices: {
        type: Array,
        default: () => [],
      },
      label: {
        type: String,
        default: 'Filter',
      },
      itemValue: {
        type: String,
        default: 'value',
      },
      itemText: {
        type: String,
        default: 'text',
      },
      icon: {
        type: String,
        default: 'fal fa-filter',
      },
      multiple: Boolean,
    },
    data () {
      return {
        loading: false,
        choice: this.value,
      }
    },
    watch: {
      value () {
        this.choice = this.value
      },
    },
  }
</script>
